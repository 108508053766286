import React from "react";
import { useDispatch } from "react-redux";
import { saveTeams } from "../../app/features/draftConfig/draftConfigSlice";
import { TeamItemDiv } from "./Teams.styles";

export const TeamItem = ({
  teams,
  num,
  teamName,
  teamLogo,
  isChecked,
  item,
  fanaticChallenge,
  teamSelectId, fanaticMode, teamSelect
}) => {
  const dispatch = useDispatch();

  // console.log(teamSelectId, "teamSelectIdTeamItem")
  // console.log(teamSelect, "fff")
  // console.log(fanaticChallenge, "fan")
  // console.log(fanaticMode,"fanMode")

  //me

  const handleClick = (team) => {
    // console.log(team, "teamItem")
    if (fanaticChallenge.length !== 0 || fanaticMode) {
      // console.log(teamSelectId, team.id, "aaa")
      const foundTeam = teamSelect?.find(item => item.id === team.id);
      if (foundTeam || teamSelectId.length === 0) {
      // if(teamSelectId.includes(team.id) || teamSelectId.length === 0) { //before
      //   console.log("step2")
        dispatch(saveTeams(team));
      }
    } else {
      // console.log("step1")
      dispatch(saveTeams(team));
    }
  };


  //the last one
  // const handleClick = (team) => {
  //   // if (fanaticChallenge.length !== 0) {
  //   //new20
  //   console.log(team, "teamItem")
  //   if (fanaticChallenge.length !== 0 || fanaticMode) {
  //     console.log(teamSelectId, team.id, "aaa")
  //     if(teamSelectId.includes(team.id) || teamSelectId.length === 0) {
  //       console.log("step2")
  //       dispatch(saveTeams(team));
  //     }
  //     else{
  //       console.log("error")
  //     }
  //   } else {
  //     console.log("step1")
  //      dispatch(saveTeams(team));
  //   }
  // };


  // const handleClick = (team) => {
  //   // if (fanaticChallenge.length !== 0) {
  //   //new20
  //   if (fanaticChallenge.length !== 0 || fanaticMode) {
  //     if(teamSelectId.includes(team.id) ) {
  //       // dispatch(saveTeams(team));
  //       alert("something went wrong")
  //     }
  //     else {
  //       dispatch(saveTeams(team));
  //     }
  //   }
  //   else {
  //     dispatch(saveTeams(team));
  //   }
  // };

  return (
    <TeamItemDiv
      onClick={() => handleClick(item)}
      className={`team-item ${isChecked ? "active" : ""}`}
    >
      <div>
        <span className="num">{item.selection}</span>
        <p className="name">{teamName}</p>
      </div>
      <img src={teamLogo} alt={teamName} />
    </TeamItemDiv>
  );
};
