import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useEffect, useRef, useState } from 'react';
// Styles
import { useDispatch, useSelector } from 'react-redux';
import {
	addPlayerToTeam,
	delRoundBPA,
	fanaticModeBefore,
	selectDraftConfig,
	setCountRender,
	setDraftPlayersAction,
	setResetInfoDraft,
	setResetTeamsDraftPlayerChoosePlayer,
	// setFanaticPickId,
	setSelectCardDepth,
	setTeamUniqPositionFanaticChallenge,
	setTradeValue,
	uniqPosition,
} from '../../app/features/draftConfig/draftConfigSlice';
import { delPlayersDraft } from '../../app/features/playersDraft/playersDraftSlice';
import { POSITIONS_COLOR } from '../../utils/constants';
import { isPositionUnique } from '../../utils/utils';
import { draftAutoSettings, draftDisableSettings } from './DraftSettings';
import { PlayerName, PlayerPos, Wrapper } from './DraftViewAsign.styles';

const Delayed = ({ children, waitBefore = 500, scroll = null }) => {
	const [isShow, setIsShow] = useState(false);
	const { countRender } = useSelector(selectDraftConfig);
	useEffect(() => {
		const timer = setTimeout(() => {
			setIsShow(true);

			scroll.teamRef?.current?.scrollTo(0, (countRender - 1) * scroll.teamRef?.current?.lastChild.clientHeight);  //check undefined
		}, waitBefore);
		return () => clearTimeout(timer);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [waitBefore]);

	function Animation() {
		return <CircularProgress style={{ position: 'absolute', right: '10%' }} />;
	}

	return isShow ? children : Animation();
};

const DraftViewAsign = ({ players, thisId }) => {
	const dispatch = useDispatch();


	const {
		teams,
		pauseId,
		timeSpeed,
		tradeValue,
		loading,
		teamPickIndex,
		countRender,
		draftCardDepth,
		draftRandomnessTeam,
		roundBPA,
		roundDepth,
		round,
		selectCardDepth,
		roundStart,
		fanaticIndexPosition,
		fanaticPickId,
		fanaticPlayerBefore,
		fanaticChallenge,
		advancedSetting,
		teamUniqPosition,
		tradingSimulatorAction,
		fanaticMode,
		teamUniqPositionFanaticChallenge,
		teamSelectIndex,
		teamDraftPlayerChoose,
		fanaticModeValue
	} = useSelector(selectDraftConfig);
	const divRef = useRef(null);
	const roundArr = useRef([]);
	// console.log(tradeValue, "trrrrr")
	// console.log(teamDraftPlayerChoose, "teamDraftPlayerChoose")
	// let fanaticPlayerBeforeRound1=[];
	// let fanaticPlayerBeforeRound2=[];
	// for(let i=0; i<fanaticPlayerBefore.length; i++){
	// 	if (Number(fanaticPlayerBefore[i].round) === 1) {
	// 		fanaticPlayerBeforeRound1.push(fanaticPlayerBefore[i])
	// 	}
	// 	else if (Number(fanaticPlayerBefore[i].round) === 2) {
	// 		fanaticPlayerBeforeRound2.push(fanaticPlayerBefore[i])
	// 	}
	// }
	const [iterationFanatic, setIterationFanatic]=useState(0);

	const teamRef = useRef(null);
	const handleSetUniquePosition = (round, name, position, iteration) => {
		if (isPositionUnique(teamUniqPositionFanaticChallenge, round, iteration, position, name)) {
			dispatch(setTeamUniqPositionFanaticChallenge({ round, name, position, iteration }));
		} else {
			console.log('Position already chosen in previous rounds/iterations.');
		}
	};

	const advancingSettings = useCallback(() => {
		if (
			tradeValue?.mouthing &&
			!players.loading &&
			players.status &&
			countRender < tradeValue?.results?.length &&
			(countRender + 1 < teamPickIndex[0] || !teamPickIndex?.length) &&
			(countRender + 1 < fanaticIndexPosition[0] || !fanaticIndexPosition?.length)
		) {
			if (!pauseId?.length) {
				let newTradeValue = {};
				let tradeValueTeam = structuredClone(tradeValue.results[countRender]);
				console.log(tradeValueTeam, "hey")
				let fanaticFlag = false;
				let teamDepth = [];

				const playersAll = players.results;
				let player = {};
				let roundIndexBool = false;
				let roundIndex = +tradeValueTeam.round_index_number;
				const playerIteration = +tradeValueTeam.iteration;
				// Team add Card Depth
				if (countRender > 0 && roundBPA?.length !== 0) {
					let teamSlice = tradeValue.results.slice(0, countRender + 1);
					teamSlice.forEach(team => {
						if (team.city === tradeValueTeam.city) {
							teamDepth = team;
						}
					});
				}
				if (roundBPA?.length && !roundBPA.includes(+tradeValueTeam.round_index_number)) {
					roundIndexBool = true;
					dispatch(delRoundBPA(tradeValueTeam.round_index_number));
				}
				// #region Stex round 2-i hama8 yntrem round 1 - 9
				// debugger;
				if (fanaticPickId?.includes(tradeValueTeam['index']) && fanaticChallenge[0]?.mode && !fanaticMode) {
					// debugger;
					if (fanaticChallenge[0]?.mode === 1) {
						const teamPickOnePlayer = teamDraftPlayerChoose[0].choosePlayers[[tradeValueTeam['iteration'] - 1]];
						// console.log(teamPickOnePlayer, "pickOne")
						if (
							teamPickOnePlayer.id !== fanaticPlayerBefore.filter(item => item.pick === tradeValueTeam['index'])[0].player.id
						) {
							player = fanaticPlayerBefore.filter(item => item.pick === tradeValueTeam['index'])[0]; //check
						}
						else {
							player = draftAutoSettings({
								draftCardDepth,
								draftRandomnessTeam,
								roundBPA,
								roundDepth,
								round,
								playersAll,
								teamDepth,
								tradeValueTeam,
								selectCardDepth,
								roundIndexBool,
								roundIndex,
								teamUniqPosition,
								fanaticChallenge,
								teamPickIndex,
								teamUniqPositionFanaticChallenge,
							});
						}
					} else if (fanaticChallenge[0]?.mode === 2) {
						if (
							tradeValueTeam['iteration'] !== 1 &&
							fanaticChallenge[0]?.mode === 2 &&
							// teamSelectIndex <= 9 &&
							+tradeValueTeam['round_index_number'] === 1 &&
							tradeValueTeam['index'] >= 24 &&
							tradeValueTeam['index'] <= 32
						) {
							player = fanaticPlayerBefore.filter(item => item.pick === tradeValueTeam['index'])[0];
						} else if (
							tradeValueTeam['iteration'] !== 1 &&
							fanaticChallenge[0]?.mode === 2 &&
							+tradeValueTeam['round_index_number'] === 2 &&
							tradeValueTeam['index'] >= 33 &&
							tradeValueTeam['index'] <= 63
						) {
							player = fanaticPlayerBefore.filter(item => item.pick === tradeValueTeam['index'])[0];
						} else {
							player = draftAutoSettings({
								draftCardDepth,
								draftRandomnessTeam,
								roundBPA,
								roundDepth,
								round,
								playersAll,
								teamDepth,
								tradeValueTeam,
								selectCardDepth,
								roundIndexBool,
								roundIndex,
								teamUniqPosition,
								fanaticChallenge,
								teamPickIndex,
								teamUniqPositionFanaticChallenge,
							});
						}
					}
				}
				// if (
				// 	fanaticPickId?.includes(tradeValueTeam['pick']) &&
				// 	+tradeValueTeam['round_index_number'] === fanaticChallenge[0]?.mode &&
				// 	!fanaticMode
				// ) {
				// 	player = fanaticPlayerBefore.filter(item => item.pick === tradeValueTeam['pick'])[0];

				// Delete before pick id
				// const filterFanaticPickId = fanaticPickId.filter(pickId => tradeValueTeam["pick"] !== pickId)
				// dispatch(setFanaticPickId(filterFanaticPickId));
				// }
				else {
					player = draftAutoSettings({
						draftCardDepth,
						draftRandomnessTeam,
						roundBPA,
						roundDepth,
						round,
						playersAll,
						teamDepth,
						tradeValueTeam,
						selectCardDepth,
						roundIndexBool,
						roundIndex,
						teamUniqPosition,
						fanaticChallenge,
						teamPickIndex,
						teamUniqPositionFanaticChallenge,
					});
				}
				//before (E)
				// if (fanaticMode) {
				// 	if (tradeValueTeam.iteration > 1 && fanaticPlayerBefore[0]?.pick === tradeValueTeam?.pick) {
				// 		player = fanaticPlayerBefore[0];
				// 		fanaticFlag = true;
					if (fanaticMode) {
						let fanaticPlayerBeforeRound1=[];
						let fanaticPlayerBeforeRound2=[];
						for(let i=0; i<fanaticPlayerBefore.length; i++){
							if (Number(fanaticPlayerBefore[i].round) === 1) {
								fanaticPlayerBeforeRound1.push(fanaticPlayerBefore[i]);
								// fanaticFlag = true;
							}
							else if (Number(fanaticPlayerBefore[i].round) === 2) {
								fanaticPlayerBeforeRound2.push(fanaticPlayerBefore[i]);
								// fanaticFlag = true;
							}
						}
						// console.log(fanaticPlayerBeforeRound1, "round1")
						// console.log(fanaticPlayerBeforeRound2, "round2")
						const index =tradeValueTeam.iteration - 2;
						console.log(tradeValueTeam, "abc");
							if (fanaticPlayerBefore[0]?.pick === tradeValueTeam?.pick) {
								console.log(tradeValueTeam.iteration, fanaticPlayerBefore[0]?.pick === tradeValueTeam?.pick, "asgh1")
								player = fanaticPlayerBefore[0]
							} else if (fanaticPlayerBefore[1]?.pick === tradeValueTeam?.pick) {
								console.log(tradeValueTeam.iteration, fanaticPlayerBefore[1]?.pick === tradeValueTeam?.pick, "asgh2")
								player = fanaticPlayerBefore[1]
							} else if (fanaticPlayerBefore[2]?.pick === tradeValueTeam?.pick) {
								console.log(tradeValueTeam.iteration, fanaticPlayerBefore[2]?.pick === tradeValueTeam?.pick, "asgh3")
								player = fanaticPlayerBefore[2]
							} else if (fanaticPlayerBefore[3]?.pick === tradeValueTeam?.pick) {
								player = fanaticPlayerBefore[3]
							} else if (fanaticPlayerBefore[4]?.pick === tradeValueTeam?.pick) {
								player = fanaticPlayerBefore[4]
							} else if (fanaticPlayerBefore[5]?.pick === tradeValueTeam?.pick) {
								player = fanaticPlayerBefore[5]
							} else if (fanaticPlayerBefore[6]?.pick === tradeValueTeam?.pick) {
								player = fanaticPlayerBefore[6]
						}
				//openMonday 2 rounds
				// 	if (fanaticMode) {
				// 		let fanaticPlayerBeforeRound1=[];
				// 		let fanaticPlayerBeforeRound2=[];
				// 		for(let i=0; i<fanaticPlayerBefore.length; i++){
				// 			if (Number(fanaticPlayerBefore[i].round) === 1) {
				// 				fanaticPlayerBeforeRound1.push(fanaticPlayerBefore[i]);
				// 				// fanaticFlag = true;
				// 			}
				// 			else if (Number(fanaticPlayerBefore[i].round) === 2) {
				// 				fanaticPlayerBeforeRound2.push(fanaticPlayerBefore[i]);
				// 				// fanaticFlag = true;
				// 			}
				// 		}
				// 		// console.log(fanaticPlayerBeforeRound1, "round1")
				// 		// console.log(fanaticPlayerBeforeRound2, "round2")
				// 		const index =tradeValueTeam.iteration - 2;
				// 		console.log(tradeValueTeam, "abc");
				// 		if(Number(tradeValueTeam.round_index_number) === 1) {
				// 			if (fanaticPlayerBeforeRound1[0]?.pick === tradeValueTeam?.pick) {
				// 				console.log(tradeValueTeam.iteration, fanaticPlayerBeforeRound1[0]?.pick === tradeValueTeam?.pick, "asgh1")
				// 				player = fanaticPlayerBeforeRound1[0]
				// 			} else if (fanaticPlayerBeforeRound1[1]?.pick === tradeValueTeam?.pick) {
				// 				console.log(tradeValueTeam.iteration, fanaticPlayerBeforeRound1[1]?.pick === tradeValueTeam?.pick, "asgh2")
				// 				player = fanaticPlayerBeforeRound1[1]
				// 			} else if (fanaticPlayerBeforeRound1[2]?.pick === tradeValueTeam?.pick) {
				// 				console.log(tradeValueTeam.iteration, fanaticPlayerBeforeRound1[2]?.pick === tradeValueTeam?.pick, "asgh3")
				// 				player = fanaticPlayerBeforeRound1[2]
				// 			} else if (fanaticPlayerBeforeRound1[3]?.pick === tradeValueTeam?.pick) {
				// 				player = fanaticPlayerBeforeRound1[3]
				// 			} else if (fanaticPlayerBeforeRound1[4]?.pick === tradeValueTeam?.pick) {
				// 				player = fanaticPlayerBeforeRound1[4]
				// 			} else if (fanaticPlayerBeforeRound1[5]?.pick === tradeValueTeam?.pick) {
				// 				player = fanaticPlayerBeforeRound1[5]
				// 			} else if (fanaticPlayerBeforeRound1[6]?.pick === tradeValueTeam?.pick) {
				// 				player = fanaticPlayerBeforeRound1[6]
				// 			}
				// 		}
				// 		else if(Number(tradeValueTeam.round_index_number) === 2) {
				// 			if (fanaticPlayerBeforeRound2[0]?.pick === tradeValueTeam?.pick) {
				// 				console.log(tradeValueTeam.iteration, fanaticPlayerBeforeRound2[0]?.pick === tradeValueTeam?.pick, "asgh1")
				// 				player = fanaticPlayerBeforeRound2[0]
				// 			} else if (fanaticPlayerBeforeRound2[1]?.pick === tradeValueTeam?.pick) {
				// 				console.log(tradeValueTeam.iteration, fanaticPlayerBeforeRound2[1]?.pick === tradeValueTeam?.pick, "asgh2")
				// 				player = fanaticPlayerBeforeRound2[1]
				// 			} else if (fanaticPlayerBeforeRound2[2]?.pick === tradeValueTeam?.pick) {
				// 				console.log(tradeValueTeam.iteration, fanaticPlayerBeforeRound2[2]?.pick === tradeValueTeam?.pick, "asgh3")
				// 				player = fanaticPlayerBeforeRound2[2]
				// 			} else if (fanaticPlayerBeforeRound2[3]?.pick === tradeValueTeam?.pick) {
				// 				player = fanaticPlayerBeforeRound2[3]
				// 			} else if (fanaticPlayerBeforeRound2[4]?.pick === tradeValueTeam?.pick) {
				// 				player = fanaticPlayerBeforeRound2[4]
				// 			} else if (fanaticPlayerBeforeRound2[5]?.pick === tradeValueTeam?.pick) {
				// 				player = fanaticPlayerBeforeRound2[5]
				// 			} else if (fanaticPlayerBeforeRound2[6]?.pick === tradeValueTeam?.pick) {
				// 				player = fanaticPlayerBeforeRound2[6]
				// 			}
				// 		}
					 else {
						// debugger;
						player = draftAutoSettings({
							draftCardDepth,
							draftRandomnessTeam,
							roundBPA,
							roundDepth,
							round,
							playersAll,
							teamDepth,
							tradeValueTeam,
							selectCardDepth,
							roundIndexBool,
							roundIndex,
							teamUniqPosition,
							teamPickIndex,
							teamUniqPositionFanaticChallenge,

						});
						//newjnjel
						// trackPicks(player);
					}
				}
				const team = tradeValue.results[countRender];
				const { player: playerItem, playerDepth } = player;
				// debugger;
				// #region addPlayerToTeam
				dispatch(
					uniqPosition({
						name: tradeValueTeam.round.name,
						position: player.player.position,  //check undefined
					})
				);
				if (fanaticChallenge.length !== 0) {
					if (team.round_index_number === '1') {
						dispatch(
							addPlayerToTeam({
								teamId: team.round.id,
								playerData: { name: playerItem.player, id: playerItem.id },
								infoDraft: { round: roundIndex,
									iteration: team.iteration,
									pick: team.pick },
							})
						);
					}
					if (
						team.round_index_number === '1' &&
						(team.iteration === 5 || team.iteration === 10) &&
						team.pick === teams.length
					) {
						dispatch(setResetTeamsDraftPlayerChoosePlayer());
						dispatch(setResetInfoDraft());
					}
				}

				// dispatch(
				// 	setTeamUniqPositionFanaticChallenge({
				// 		round: +tradeValueTeam.round_index_number,
				// 		name: tradeValueTeam.round.name,
				// 		position: player.player.position,
				// 		iteration: tradeValueTeam.iteration,
				// 	})
				// );
				handleSetUniquePosition(
					+tradeValueTeam.round_index_number,
					tradeValueTeam.round.name,
					player.player.position,
					tradeValueTeam.iteration
				);
				tradeValueTeam['player'] = playerItem;
				tradeValueTeam['playerDepth'] = playerDepth;
				if(fanaticMode){
					tradeValueTeam['iteration'] = playerIteration;
				}
				let newTradeValueResults = tradeValue.results.map(team => {
					if (team['index_position']) {
						return team['index_position'] === tradeValueTeam['index_position'] ? tradeValueTeam : team;
					} else {
						return team.index === tradeValueTeam.index ? tradeValueTeam : team;
					}
				});

				newTradeValue = {
					...tradeValue,
					results: newTradeValueResults,
				};
				if (!fanaticMode) {
					!selectCardDepth.includes(playerDepth) && dispatch(setSelectCardDepth(playerDepth));
				}

				dispatch(setTradeValue(newTradeValue));
				dispatch(setDraftPlayersAction(tradeValueTeam));
				dispatch(delPlayersDraft([playerItem], tradeValueTeam?.iteration));
				dispatch(setCountRender());
				if (fanaticFlag) {
					dispatch(fanaticModeBefore({ player, action: 'dec' }));
				}
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradeValue.mouthing, players.loading, pauseId, players.status]);

	const advancingSettingsDefault = useCallback(() => {
		// debugger;
		if (
			tradeValue?.mouthing &&
			!players.loading &&
			players.status &&
			countRender < tradeValue.results.length &&
			(countRender + 1 < teamPickIndex[0] || !teamPickIndex.length) &&
			!pauseId.length
		) {
			let newTradeValue = {};
			let tradeValueTeam = structuredClone(tradeValue.results[countRender]);

			const playersAll = players.results;
			// let player = {};
			let roundIndex = +tradeValueTeam.round_index_number;
			let { player } = draftDisableSettings({
				teamUniqPosition,
				playersAll,
				tradeValueTeam,
				roundIndex,
				round,
			});
			tradeValueTeam['player'] = player;

			let newTradeValueResults = tradeValue.results.map(team => {
				return team.index === tradeValueTeam.index ? tradeValueTeam : team;
			});

			newTradeValue = {
				...tradeValue,
				results: newTradeValueResults,
			};

			dispatch(
				uniqPosition({
					name: tradeValueTeam.round.name,
					position: player.position,
				})
			);
			// debugger;
			handleSetUniquePosition(
				+tradeValueTeam.round_index_number,
				tradeValueTeam.round.name,
				player.player.position,
				tradeValueTeam.iteration
			);
			dispatch(setTradeValue(newTradeValue));
			dispatch(setDraftPlayersAction(tradeValueTeam));
			dispatch(delPlayersDraft([player], tradeValueTeam?.iteration));
			dispatch(setCountRender());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradeValue.mouthing, players.loading, pauseId, players.status]);

	useEffect(() => {
		if (!advancedSetting && !tradingSimulatorAction) {
			advancingSettings();
			return;
		}
		advancingSettingsDefault();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tradeValue.mouthing, players.loading, pauseId, players.status]);

	function delayTime({ id, indexPosition }) {
		const isTeamPick = currentValue => currentValue > id;
		const isIndexPosition = currentValue => currentValue > indexPosition;

		if (fanaticIndexPosition?.length) {
			return fanaticIndexPosition.every(isIndexPosition) ? indexPosition * (1000 / timeSpeed / indexPosition) : 0;
		} else if (!fanaticIndexPosition?.length) {
			return teamPickIndex.every(isTeamPick) ? id * (1000 / timeSpeed / id) : 0;
		} else {
			return;
		}
	}





	return (
		<Wrapper ref={divRef}>
			{players?.length > 0 && loading ? <CircularProgress /> : null}
			<ul ref={teamRef}>
				{tradeValue?.results?.map((team, idx) => {
					const {
						index: id,
						round_index: roundIndex,
						index_position: indexPosition,
						round: { logo, name },
						iteration,
						team_neads_info,
					} = team;
					let position = team_neads_info.team_neads_info.map(item => item.positions).flat();

					const checkTeam = delayTime({ id, indexPosition });
					const time = thisId ? +(id - thisId) * (1000 / timeSpeed) : checkTeam;
					const teamActive = fanaticIndexPosition?.length
						? fanaticIndexPosition?.includes(indexPosition)
						: teamPickIndex.includes(id);
					// Round Text
					if (roundStart.includes(id)) {
						roundArr.current = [];
					}
					const roundCheck = roundArr.current.includes(roundIndex) ? false : roundArr.current.push(roundIndex);

					return (
						<React.Fragment key={idx}>
							{roundCheck ? (
								<li className="round" key={Math.random()}>
									{roundIndex} {iteration && `Iteration ${iteration}`}
								</li>
							) : null}
							<li key={id} className={`${teamActive ? 'player-team active' : 'player-team'}`}>
								<div className="heto-poxel">
									{team?.index_position ? (
										<div className="pick">
											<p>Pick</p>
											<p>{id}</p>
										</div>
									) : (
										<div className="pick">
											<p>Pick</p>
											<p>{id}</p>
										</div>
									)}
									<div className="player-team-info">
										<img src={logo ? logo : ''} alt={name} />
										{!!checkTeam && team?.player ? null : (
											<>
												{teamActive ? (
													<div className="player-click">On The Clock</div>
												) : (
													<div className="player-dashed">- - -</div>
												)}
											</>
										)}
										{!!checkTeam && (
											<Delayed
												waitBefore={time}
												scroll={{
													teamRef,
													id,
													thisId,
													player: team.player ? true : false,
												}}
											>
												{team?.player ? (
													<>
														<PlayerName>{team?.player?.player}</PlayerName>
														<PlayerPos backColor={POSITIONS_COLOR[team?.player?.position]}>
															{team.player.position}
														</PlayerPos>
														<p className="player-college">{team?.player?.school}</p>
													</>
												) : (
													<CircularProgress style={{ position: 'absolute', right: '10%' }} />
												)}
											</Delayed>
										)}
									</div>
								</div>

								<div className="needs">
									{position.map((need, idx) => (
										<p key={idx}>{need.name}</p>
									))}
								</div>
							</li>
						</React.Fragment>
					);
				})}
			</ul>
		</Wrapper>
	);
};

export default DraftViewAsign;
